import Layout from "components/layout";
import React, { useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { useSubscribe } from "hooks/Forms/useSubscribe";
import { useFetchTips } from "hooks/Tips/useFetchTips";
import Loader from "components/loader";
import SEO from "components/seo";
import { useLocation } from "@reach/router";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";

export default function TranslationTips() {
  const [formStatus, setForStatus] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const { mutateAsync } = useSubscribe();

  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchTips();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      pagelink: location.href,
      consent: data.consent
    };
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset();
      setForStatus(true);
    });

  };
  const description =
    "Important advice and valuable information here for freelance translators by fellow professional translators and freelancers";
  const title =
    "The Big List of Freelance Translators' Tips | Tomedes Translation";
  const keywords =
    "translation tips, tips for translators, freelance tips, advice for translators";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/translation-tips"
      />
      <div>
        <div className="mainContainer">
          <div className="bannerSection bg-language-facts flex flex-col flex-1 bg-no-repeat bg-cover bg-center h-[500px] sm:relative">
            <div className="box bg-white sm:px-10 sm:py-6 opacity-90 flex flex-col w-full sm:w-4/12 mx-auto my-auto h-fit px-3 sm:absolute sm:top-[10rem] md:top-[10.5rem] sm:left-[25.5rem] ">
              <h5 className="text-[#4f4f4f] text-4xl">TRANSLATOR'S TIPS</h5>
              <p className="text-[#2664e4] text-2xl pt-3">
                Have a tip to share? Share with us
              </p>
            </div>
            <div className="blackStrip bg-black opacity-80 py-3 mt-auto">
              <div className="socialIcons flex w-5/12 mx-auto gap-x-4">
                <a href="">
                  {" "}
                  <img
                    src="https://tomedes.gumlet.io/frontend/images/in.png"
                    alt=""
                  />
                </a>
                <a href="">
                  {" "}
                  <img
                    src="https://tomedes.gumlet.io/frontend/images/tw.png"
                    alt=""
                  />
                </a>
                <a href="">
                  {" "}
                  <img
                    src="https://tomedes.gumlet.io/frontend/images/fb.png"
                    alt=""
                  />
                </a>
                <a href="">
                  {" "}
                  <img
                    src="https://tomedes.gumlet.io/frontend/images/gp.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="bottomSection bg-[#1d1c1c] py-8">
            <div className="contenT w-full  lg:w-9/12 flex flex-col lg:flex-row mx-auto justify-center px-5 xl:px-0 gap-x-0 lg:gap-x-16 xl:gap-x-32">
              <div className="left w-full lg:9/12 xl:w-6/12 ">
                <div className="flex md:flex-row flex-col justify-between pb-6">
                  <h5 className="text-lightBlue text-2xl">TRANSLATORS TIPS</h5>
                  <div className="div flex sm:flex-row flex-col">
                    <div className="div flex sm:pt-0 pt-8">
                      <label
                        htmlFor=""
                        className="text-white flex items-center pr-3 text-xs"
                      >
                        Sort By
                      </label>
                      <select name="" id="" className="rounded">
                        <option value="one">Last Added</option>
                        <option value="one">Popularity</option>
                      </select>
                    </div>

                    <div className="div pl-[51px] pt-4 sm:pt-0 sm:pl-4 flex">
                      <select name="" id="" className="rounded">
                        <option value="one">Last Added</option>
                        <option value="one">Popularity</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="borderLine border-b border-gray"></div>
                {data?.pages.map((page) =>
                  page.data.map((c, i) => (
                    <div className="populatedData relative" key={i}>
                      <h5 className="font-semibolds text-lightBlue text-sm pt-3">
                        {c.name}
                      </h5>
                      <p className=" font-extralight text-sm  text-white">
                        {" "}
                        {c.tips}{" "}
                      </p>
                      <h5 className="absolute pt-2 right-0 text-drakGray font-primary">
                        {c.posted_date}
                      </h5>
                      <div className="border-b border-gray pt-12"></div>
                    </div>
                  ))
                )}

                <div className="flex justify-between">
                <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                  <Link
                    to="/translation-tips/sharetip"
                    className="bg-lightBlue px-3 py-3 mt-3 text-white font-semibold text-sm lg:mb-0 mb-6"
                  >
                    Add a tip
                  </Link>
                  {hasNextPage && (
                    <button
                      onClick={fetchNextPage}
                      className="bg-lightBlue px-3 py-3 mt-3 text-white font-semibold text-sm lg:mb-0 mb-6"
                    >
                      {isFetching ? <Loader /> : "Show more"}
                    </button>
                  )}
                </div>
              </div>

              <div className="righT w-full lg:w-6/12 xl:w-3/12 ">
                <div className="top flex flex-col">
                  <h5 className="text-lightBlue text-xl">Most Popular</h5>
                  <div className="flex text-white pt-8 xl:text-base lg:text-xs font-semibold">
                    <img
                      src="https://tomedes.com/documents/CustomerLogo/tokyo2021_557x350_21-7-21_23-57-26.jpg"
                      className="w-24"
                      alt=""
                    />
                    <a
                      href="https://www.tomedes.com/translator-hub/olympics-tokyo-2020ne"
                      target="_blank"
                      className="pl-4"
                    >
                      Interpretation In This Year's Summer Olympics .. »
                    </a>
                  </div>
                  <div className="flex text-white pt-8 xl:text-base lg:text-xs font-semibold">
                    <img
                      src="https://tomedes.com/documents/CustomerLogo/adult-afro-blazer-1080847_6-11-19_08-39-18.jpg"
                      className="w-24"
                      alt=""
                    />
                    <a
                      href="https://www.tomedes.com/translator-hub/work-life-balance-translator"
                      target="_blank"
                      className="pl-4"
                    >
                      How to Maintain a Healthy Work-Life Balance as a .. »
                    </a>
                  </div>
                  <div className="flex text-white pt-8 xl:text-base lg:text-xs font-semibold">
                    <img
                      src="https://www.tomedes.com/documents/CustomerLogo/How%20to%20learn%20Spanish%20effectively%20small_9-10-19_05-07-20.jpg"
                      className="w-24"
                      alt=""
                    />
                    <a
                      href="https://www.tomedes.com/translator-hub/learn-spanish-effectively"
                      target="_blank"
                      className="pl-4"
                    >
                      How to Learn Spanish Effectively »
                    </a>
                  </div>
                </div>
                <div className="newsletter bg-[#133a8d] py-4 px-4 mt-16 lg:w-[283px] sm:w-80 flex flex-col sm:justify-start justify-center sm:items-start items-center">
                  <div className="flex text-white gap-x-6">
                    <img
                      src="https://www.tomedes.com/skin/frontend/images/news_mes.png"
                      alt=""
                      className=""
                    />
                    <h5 className="leading-none font-medium">
                      Sign up to our <br />
                      newsletter
                    </h5>
                  </div>
                  <form
                    className="flex flex-col pt-4"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {errors.email && (
                      <span className="text-red text-sm pb-1">
                        {errorMessageEmail}
                      </span>
                    )}
                    <div className="flex justify-center items-center max-w-max">
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        className="pl-2 py-2 italic text-xs"
                        {...register("email", {
                          required: true,
                          pattern: patternEmail,
                        })}
                      />
                      <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                      <button
                        type="submit"
                        className="text-white font-semibold text-sm bg-[#353535] px-2 py-2"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="my-3">
                      <div className="flex items-baseline gap-2">
                        <input
                          type="checkbox"
                          name="consent"
                          id="consent"
                          {...register("consent", {
                          })}
                          onChange={() => setCheckBox((value) => !value)}
                        />
                        <p className="text-sm text-white font-opensans">
                          I agree to Tomedes'{" "}
                          <Link
                            to="/privacy"
                            className="text-orange hover:text-newOrange-100 underline transition-colors"
                          >
                            Privacy Policy.
                          </Link>{" "}
                          by submitting this form.
                        </p>
                      </div>
                      {errors.privacy_policy && (
                        <span className="text-red text-sm font-primary mb-[10px] mt-[-16px]">
                          Please tick above to agree terms & conditions
                        </span>
                      )}
                    </div>

                    {formStatus ? (
                      <span className="text-[#96D149] text-sm pt-2">
                        Thank you! Please check your <br />
                        inbox for a confirmation email
                      </span>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
