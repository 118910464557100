import { useInfiniteQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchTips = ({ pageParam = 1 }) => {
  const newEndpoints = endpoints.tips.getTips.replace(
    "?page={pagenumber}",
    "?page=" + pageParam
  );
  return http().get(newEndpoints);
};

export function useFetchTips() {
  return useInfiniteQuery(["fetchTips"], fetchTips, {
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page < lastPage.meta.total)
        return lastPage.meta.current_page + 1;
      return false;
    },
  });
}
